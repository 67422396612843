import UserCrud from "../components/user/UserCrud";

const UserPage = () => {
  return (
    <>
      <UserCrud />
    </>
  );
};

export default UserPage;
