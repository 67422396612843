import { ThunkDispatch } from "redux-thunk";

import { UpdateUserAction } from "../types/user";
import {
  SET_LOGIN_INITIALIZED,
  SET_USER_DATA,
  SET_LOGGING_IN,
  LOG_IN,
  LOG_OUT,
  SET_LOGING_FAILED,
  REFRESH_TOKEN,
  SET_USER_DATA_LOADING,
} from "../reducers/user";
import {
  getJWTToken,
  refreshJWTToken,
  clearRefreshToken,
  getUserData,
} from "../../api/auth";

// Async example here: https://medium.com/@peatiscoding/typescripts-with-redux-redux-thunk-recipe-fcce4ffca405
export const login =
  (email: string, password: string) =>
  async (dispatch: ThunkDispatch<{}, {}, UpdateUserAction>) => {
    dispatch({ type: SET_LOGGING_IN, payload: {} });

    const loginData = await getJWTToken(email, password);
    const success = loginData.status === "success";

    if (success) {
      dispatch({
        type: LOG_IN,
        payload: {
          accessToken: loginData.accessToken,
          email: email,
        },
      });
      dispatch({
        type: SET_USER_DATA_LOADING,
        payload: {},
      });
      const userData = await getUserData();

      if (userData.status === "success") {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            email: userData.userData ? userData.userData.email : "",
            id: userData.userData ? userData.userData.id : 0,
            isSuperUser: userData.userData
              ? userData.userData.isSuperUser
              : false,
          },
        });
      }
    } else {
      dispatch({
        type: SET_LOGING_FAILED,
        payload: {
          loginFailed: true,
        },
      });
    }

    return success;
  };

export const refreshToken =
  () => async (dispatch: ThunkDispatch<{}, {}, UpdateUserAction>) => {
    const refreshData = await refreshJWTToken();

    const success = refreshData.status === "success";

    if (success) {
      dispatch({
        type: REFRESH_TOKEN,
        payload: {
          accessToken: refreshData.accessToken,
        },
      });
    }

    return success;
  };

export const setLoginFailed = (): UpdateUserAction => {
  return {
    type: SET_LOGING_FAILED,
    payload: {
      loginFailed: true,
    },
  };
};

export const logOut =
  () => async (dispatch: ThunkDispatch<{}, {}, UpdateUserAction>) => {
    await clearRefreshToken();

    dispatch({
      type: LOG_OUT,
      payload: {},
    });
  };

export const initializeLogin =
  () => async (dispatch: ThunkDispatch<{}, {}, UpdateUserAction>) => {
    const refreshData = await refreshJWTToken();
    const success = refreshData.status === "success";

    if (success) {
      dispatch({
        type: SET_LOGIN_INITIALIZED,
        payload: {
          accessToken: refreshData.accessToken,
          loggedIn: true,
        },
      });
      dispatch({
        type: SET_USER_DATA_LOADING,
        payload: {},
      });
      const userData = await getUserData();

      if (userData.status === "success") {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            id: userData.userData?.id,
            email: userData.userData ? userData.userData.email : "",
            isSuperUser: userData.userData
              ? userData.userData.isSuperUser
              : false,
          },
        });
      }
    } else {
      dispatch({
        type: SET_LOGIN_INITIALIZED,
        payload: {
          accessToken: refreshData.accessToken,
          loggedIn: false,
        },
      });
    }
  };
