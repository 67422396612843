import React, { useEffect, useRef, useState } from "react";

import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";

import Loading from "../indicators/Loading";
import TaxPayer, { TAX_PAYER_TYPE_CHOICES } from "../../models/tax-payer";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import {
  createItem,
  getList,
  ItemRequestStatus,
  updateItem,
} from "../../api/generics";
import RegimeTypeSelect from "../regime-type/RegimeTypeSelect";
import RegimeType from "../../models/regime-type";

interface TaxPayerFormProps {
  taxPayer?: TaxPayer;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const TaxPayerForm: React.FC<TaxPayerFormProps> = ({
  taxPayer,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [defaultRegimeType, setDefaultRegimeType] = useState<RegimeType | null>(
    null
  );

  const taxPayerRef = useRef<TaxPayer | null>(
    taxPayer !== undefined ? taxPayer : null
  );

  const updateTaxPayerRef = (taxPayer: TaxPayer) => {
    taxPayerRef.current = taxPayer;
  };

  const onRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.ruc = e.target.value;
  };

  const onSocialNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.socialName = e.target.value;
  };

  const onFantasyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.fantasyName = e.target.value;
  };

  const onRegimeTypeChange = (newRegimeType: RegimeType | null) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.regimeTypeId = newRegimeType?.id;
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.type = e.target.value;
  };

  const loadTaxPayerData = async (newTaxPayer: TaxPayer) => {
    setLoading(true);
    const regimeType = await loadInitialRegimeType(newTaxPayer.regimeTypeId);
    setDefaultRegimeType(regimeType);

    setLoading(false);
  };

  const loadInitialRegimeType = async (
    id?: number
  ): Promise<RegimeType | null> => {
    if (id === undefined) {
      return null;
    }
    const additionalParams = new Map();
    additionalParams.set("id", id.toString());
    const initialRegimeTypeStatus = await getList<RegimeType>(
      "/regime_types/",
      1,
      0,
      additionalParams
    );

    if (
      initialRegimeTypeStatus.status === SUCCESS &&
      initialRegimeTypeStatus.data !== undefined &&
      initialRegimeTypeStatus.data.items.length > 0
    ) {
      return initialRegimeTypeStatus.data.items[0];
    }

    return null;
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateTaxPayerRef({});
  };

  useEffect(() => {
    if (taxPayer !== undefined) {
      loadTaxPayerData(taxPayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  if (loading) {
    return <Loading />;
  }

  const onSaveClick = async () => {
    if (taxPayerRef.current === null) {
      return;
    }

    setSubmitting(true);
    setDisabled(true);

    const taxPayer = taxPayerRef.current;

    let requestPromise: Promise<ItemRequestStatus<TaxPayer>>;

    if (taxPayer.id === undefined) {
      requestPromise = createItem("/tax_payers/create/", taxPayer);
    } else {
      requestPromise = updateItem(`/tax_payers/${taxPayer.id}/`, taxPayer);
    }

    const taxPayerRequestStatus = await requestPromise;

    if (taxPayerRequestStatus.status !== SUCCESS) {
      let message = "Ha ocurrido un error!!";
      if (taxPayerRequestStatus.detail !== undefined) {
        message = taxPayerRequestStatus.detail;
      }
      errorAlert(message);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    setErrors(taxPayerRequestStatus.errors!);

    setSubmitting(false);
    setDisabled(false);
  };

  return (
    <>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> RUC
            </CLabel>
          </CCol>
          <CCol>
            <CInput
              type="text"
              placeholder="Ej: 123456-7"
              disabled={disabled}
              defaultValue={taxPayerRef.current ? taxPayerRef.current.ruc : ""}
              onChange={onRucChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("ruc", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Nombre Social
            </CLabel>
          </CCol>
          <CCol>
            <CInput
              type="text"
              placeholder="Ej: Mi Compañía SRL"
              disabled={disabled}
              onChange={onSocialNameChange}
              defaultValue={
                taxPayerRef.current ? taxPayerRef.current.socialName : ""
              }
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("socialName", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Nombre de Fantasía
            </CLabel>
          </CCol>
          <CCol>
            <CInput
              type="text"
              placeholder="Ej: Mi Compañía"
              disabled={disabled}
              onChange={onFantasyNameChange}
              defaultValue={
                taxPayerRef.current ? taxPayerRef.current.fantasyName : ""
              }
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("fantasyName", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Tipo de Contribuyente:
            </CLabel>
          </CCol>
          <CCol md={2}>
            <CSelect
              type="text"
              defaultValue={taxPayerRef.current ? taxPayerRef.current.type : ""}
              onChange={onTypeChange}
            >
              <option value={""} disabled>
                -----
              </option>
              {Array.from(TAX_PAYER_TYPE_CHOICES.entries()).map((entry) => {
                return (
                  <option key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </option>
                );
              })}
            </CSelect>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Régimen:
            </CLabel>
          </CCol>
          <CCol>
            <CInputGroup>
              <RegimeTypeSelect
                defaultValue={defaultRegimeType}
                onChange={onRegimeTypeChange}
              ></RegimeTypeSelect>
            </CInputGroup>
            <FieldErrors
              errors={getFieldErrors("clientName", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup className="float-right">
        <CButtonGroup>
          <CButton type="button" color="secondary" onClick={onClose}>
            Salir
          </CButton>
          <CButton
            className="btn btn-primary"
            disabled={disabled}
            onClick={onSaveClick}
          >
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CButtonGroup>
      </CFormGroup>
    </>
  );
};

export default TaxPayerForm;
