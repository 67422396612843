import DashboardPage from "./dashboard-pages/DashboardPage";
import TaxPayerPage from "./dashboard-pages/TaxPayerPage";
import UserPage from "./dashboard-pages/UserPage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserPage,
  },
  taxPayers: {
    path: "/tax_payers/",
    name: "Contribuyentes",
    exact: true,
    component: TaxPayerPage,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
