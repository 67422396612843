import { useEffect, useState } from "react";
import TaxPayer from "../../models/tax-payer";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import { getRoutePath } from "../../routes";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CTooltip,
} from "@coreui/react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import TaxPayerUpdateModal from "./TaxPayerUpdateModal";

const ITEMS_PER_PAGE = 20;

const TaxPayerCrud = () => {
  const fields = [
    {
      key: "ruc",
      _classes: ["text-center"],
      label: "RUC",
    },
    { key: "socialName", _classes: ["text-center"], label: "Razón Social" },
    {
      key: "fantasyName",
      _classes: ["text-center"],
      label: "Nombre de Fantasía",
    },
    {
      key: "type",
      _classes: ["text-center"],
      label: "Tipo",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<TaxPayer[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [showTaxPayerEditModal, setShowTaxPayerEditModal] = useState(false);
  const [taxPayer, setTaxPayer] = useState<TaxPayer | undefined>(undefined);

  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchItems = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const itemsRequestStatus = await getList<TaxPayer>(
      "/tax_payers/",
      limit,
      offset
    );

    if (itemsRequestStatus.status === SUCCESS) {
      if (itemsRequestStatus.data !== undefined) {
        const count = itemsRequestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setItems(itemsRequestStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = itemsRequestStatus.detail
        ? itemsRequestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }

    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setTaxPayer(undefined);
    setShowTaxPayerEditModal(true);
  };

  const onUpdateClick = (taxPayer: TaxPayer) => {
    setTaxPayer(taxPayer);
    setShowTaxPayerEditModal(true);
  };

  const onTaxPayerEditCancel = () => {
    setShowTaxPayerEditModal(false);
  };

  const onTaxPayerEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowTaxPayerEditModal(false);
    setItems([]);
    setLoading(true);
    //setTotalPages(1);
    //setCurrentPage(1);
    //reset();
    fetchItems();
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("taxPayers")) {
        return;
      }
      fetchItems();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Contribuyentes</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={items}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    actions: (item: TaxPayer) => {
                      const editButton = (
                        <CTooltip content="Editar">
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </CTooltip>
                      );

                      return (
                        <td className="text-center">
                          <CButtonGroup>{editButton}</CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <TaxPayerUpdateModal
        show={showTaxPayerEditModal}
        onCancel={onTaxPayerEditCancel}
        onSuccess={onTaxPayerEditSuccess}
        taxPayer={taxPayer}
      />
    </>
  );
};

export default TaxPayerCrud;
