import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CInputGroup,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem, updateItem, ItemRequestStatus } from "../../api/generics";
import User, { newUser } from "../../models/user";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { emptyValueOnUndefined } from "../../utils/fields";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";

interface UserFormProps {
  initialUser?: User;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const UserForm: React.FC<UserFormProps> = ({
  initialUser,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingUser, setEditingUser] = useState<User>(
    initialUser ? initialUser : newUser()
  );

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      firstName: e.target.value,
    });
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      lastName: e.target.value,
    });
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      username: e.target.value,
      email: e.target.value,
    });
  };

  const onIsSuperUserChange = (newIsSuperUser: boolean) => {
    setEditingUser({
      ...editingUser,
      isSuperUser: newIsSuperUser,
    });
  };

  const onPassword1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password1: e.target.value,
    });
  };

  const onPassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password2: e.target.value,
    });
  };

  const onPassword1ShowClick = () => {
    setShowPassword1(!showPassword1);
  };

  const onPassword2ShowClick = () => {
    setShowPassword2(!showPassword2);
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendUser = {
      ...editingUser,
    };
    let requestPromise: Promise<ItemRequestStatus<User>>;
    if (editingUser.id === 0) {
      requestPromise = createItem<User>("/users/create/", toSendUser);
    } else {
      requestPromise = updateItem<User>(`/users/${toSendUser.id}/`, toSendUser);
    }

    const userStatus = await requestPromise;

    if (userStatus.status !== SUCCESS) {
      if (userStatus.errors !== undefined) {
        setErrors(userStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (userStatus.detail !== undefined) {
        message = userStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingUser(newUser());
  };

  useEffect(() => {
    setEditingUser(initialUser ? initialUser : newUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingUser.firstName)}
                onChange={onFirstNameChange}
                placeholder="Ej: Nombre"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("firstName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Apellido</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingUser.lastName)}
                onChange={onLastNameChange}
                placeholder="Ej: Apellido"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("lastName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Correo electrónico</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={emptyValueOnUndefined(editingUser.email)}
                onChange={onEmailChange}
                placeholder="Ej: Correo electrónico"
                autoComplete="new-email"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
              <FieldErrors
                errors={getFieldErrors("username", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Super Usuario:</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  value={editingUser.isSuperUser === true}
                  onChange={onIsSuperUserChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("enabled", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {initialUser?.id !== 0 ? (
          <></>
        ) : (
          <>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Contraseña</CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <CInput
                      type={showPassword1 ? "text" : "password"}
                      value={emptyValueOnUndefined(editingUser.password1)}
                      onChange={onPassword1Change}
                      placeholder="Ej: Contraseña"
                      autoComplete="new-password"
                    ></CInput>
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onPassword1ShowClick}
                    >
                      {showPassword1 ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </CButton>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("password1", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Repita la Contraseña</CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <CInput
                      type={showPassword2 ? "text" : "password"}
                      value={emptyValueOnUndefined(editingUser.password2)}
                      onChange={onPassword2Change}
                      placeholder="Ej: Repita la Contraseña"
                      autoComplete="new-password"
                    ></CInput>
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onPassword2ShowClick}
                    >
                      {showPassword2 ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </CButton>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("password2", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        )}
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default UserForm;
