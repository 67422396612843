const SuperUserRequired = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h2>No tienes permiso para acceder a esta página</h2>
    </div>
  );
};

export default SuperUserRequired;
