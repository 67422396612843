import React from "react";
import TaxPayer from "../../models/tax-payer";
import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import TaxPayerForm from "./TaxPayerForm";

interface TaxPayerUpdateModalProps {
  show: boolean;
  taxPayer?: TaxPayer;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const TaxPayerUpdateModal: React.FC<TaxPayerUpdateModalProps> = ({
  show,
  taxPayer,
  onCancel,
  onSuccess,
}) => {
  const modalTitle = taxPayer
    ? `Editar Contribuyente: ${taxPayer.ruc}`
    : `Agregar Contribuyente`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <TaxPayerForm
              taxPayer={taxPayer}
              onCancel={onCancel}
              onSuccess={onSuccess}
              clearOnSuccess={false}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default TaxPayerUpdateModal;
