import React from "react";

import { Route } from "react-router-dom";

import { useSelector } from "react-redux";

import { RootState } from "../store";
import SuperUserRequired from "../components/permissions/SuperUserRequired";

interface SuperUserRequiredRouteProps {
  children: React.ReactNode;
}

const SuperUserRequiredRoute = (props: SuperUserRequiredRouteProps) => {
  const user = useSelector((state: RootState) => state.user);

  if (user.isSuperUser !== true) {
    return <SuperUserRequired />;
  }

  return <Route {...props}></Route>;
};

export default SuperUserRequiredRoute;
